.highlight {
    left: 1.75rem;
    border-radius: 6px;
    position: absolute;
    width: 74px;
    height: 62px;
    background: linear-gradient(120deg, rgba(32,135,240,1) 0%, rgba(24,115,207,1) 100% );
    box-shadow: inset 0 1px 1px 0px rgba(0, 0, 0, 0.2);
    transition: transform 200ms ease-in-out;
}

@media screen and (min-width: 705px) {
    .highlight {
        left: 1.75rem;
        border-radius: 6px;
        position: absolute;
        width: 75px;
        height: 62px;
        background: linear-gradient(120deg, rgba(32,135,240,1) 0%, rgba(24,115,207,1) 100% );
        box-shadow: inset 0 1px 1px 0px rgba(0, 0, 0, 0.2);
        transition: transform 200ms ease-in-out;
    }
}

@media screen and (min-width: 770px) {
    .highlight {
        left: 1.75rem;
        border-radius: 6px;
        position: absolute;
        width: 80px;
        height: 62px;
        background: linear-gradient(120deg, rgba(32,135,240,1) 0%, rgba(24,115,207,1) 100% );
        box-shadow: inset 0 1px 1px 0px rgba(0, 0, 0, 0.2);
        transition: transform 200ms ease-in-out;
    }
}

@media screen and (min-width: 820px) {
    .highlight {
        left: 1.75rem;
        border-radius: 6px;
        position: absolute;
        width: 84px;
        height: 62px;
        background: linear-gradient(120deg, rgba(32,135,240,1) 0%, rgba(24,115,207,1) 100% );
        box-shadow: inset 0 1px 1px 0px rgba(0, 0, 0, 0.2);
        transition: transform 200ms ease-in-out;
    }
}

@media screen and (min-width: 890px) {
    .highlight {
        left: 1.75rem;
        border-radius: 6px;
        position: absolute;
        width: 88px;
        height: 62px;
        background: linear-gradient(120deg, rgba(32,135,240,1) 0%, rgba(24,115,207,1) 100% );
        box-shadow: inset 0 1px 1px 0px rgba(0, 0, 0, 0.2);
        transition: transform 200ms ease-in-out;
    }
}

@media screen and (min-width: 950px) {
    .highlight {
        left: 1.75rem;
        border-radius: 6px;
        position: absolute;
        width: 96px;
        height: 62px;
        background: linear-gradient(120deg, rgba(32,135,240,1) 0%, rgba(24,115,207,1) 100% );
        box-shadow: inset 0 1px 1px 0px rgba(0, 0, 0, 0.2);
        transition: transform 200ms ease-in-out;
    }
}

@media screen and (min-width: 1239px) {
    .highlight {
        left: 1.75rem;
        border-radius: 6px;
        position: absolute;
        width: 138px;
        height: 62px;
        background: linear-gradient(120deg, rgba(32,135,240,1) 0%, rgba(24,115,207,1) 100% );
        box-shadow: inset 0 1px 1px 0px rgba(0, 0, 0, 0.2);
        transition: transform 200ms ease-in-out;
    }
}
