* {
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}

html {
  font-size: 16px;
}

body {
  max-width: 1242px;
  margin: auto;
}

.App {
  text-align: center;
  font-family: "spoqa_han_sans_neo";
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide-scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.place-color-grey::placeholder {
  color: #7b7b7b;
}

.place-color-grey-1::placeholder {
  color: #8d8d8d;
}

.place-color-grey-2::placeholder {
  color: #bebebe;
}

.text-white-border {
  -webkit-text-stroke: 0.125rem white;
}

.date-picker {
  padding-left: 1.9375rem;
  padding-right: 1.875rem;
}


@media only screen and (max-width: 200px) {
  .small-padding {
    padding: 0.5px;
  }
  .small-padding.p-px {
    width: 12rem !important;
  }
}


.common-button {
  padding: 1px;
}

.common-button > div {
  width: calc(100% - 2px);
  height: calc(100% - 2px);
}

@media only screen and (max-width: 767px) {
  .common-button {
    padding: 0.3px;
  }
  .common-button > div {
    width: calc(100% - 1.5px); 
    height: calc(100% - 1.5px);
    padding: 1.5rem;
    padding-top: 2rem;
  }
 }
